import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/img/header/logo.svg'

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__wrapper'>
          <div className='footer__logo'>
            <NavLink to='/'>
              <Logo />
            </NavLink>
          </div>
          <div className='footer__card'>
            <h4 className='footer__card-title'>Navigation</h4>
            <nav className='footer__nav'>
              <ul className='footer__card-list'>
                <li>
                  <NavLink to='/'>Reservation Process</NavLink>
                </li>
                <li>
                  <NavLink to='/'>Our Value</NavLink>
                </li>
                <li>
                  <NavLink to='/'>About Us</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className='footer__card'>
            <h4 className='footer__card-title'>Tenants</h4>
            <ul className='footer__card-list'>
              <li>
                <NavLink to='/'>Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to='/'>Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className='footer__card'>
            <h4 className='footer__card-title'>Resources</h4>
            <ul className='footer__card-list'>
              <li>
                <p>
                  1000922513 ONTARIO LIMITED 151 Yonge Street, Suite 1102,
                  Toronto, Ontario, M5C2W7, Canada
                </p>
              </li>
              <li>
                <a href='tel:14167704099'>+14167704099</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
