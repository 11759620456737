import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { RoomsCard } from '../components/RoomsCard'
import roomsData from '../data/roomsData.json'
import { Datapicker } from '../components/inputs/Datapicker'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import { FreeMode, EffectFade, Navigation, Thumbs } from 'swiper/modules'

function RoomsDetailPage() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { roomsId } = useParams()
  const thisRooms = roomsData.find((prod) => String(prod.id) === roomsId)

  const [checkInDate, setCheckInDate] = useState('')
  const [checkOutDate, setCheckOutDate] = useState('')
  return (
    <>
      <div className='single__container'>
        <div className='single-layout'>
          <section className='single-layout__product single-product'>
            <div className='single-product__gallery'>
              <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff',
                }}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, EffectFade, Navigation, Thumbs]}
                className='mySwiper2'
                navigation={true}
                effect={'fade'}
              >
                {thisRooms.img.map((img, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <img src={require(`../assets/img/rooms/${img}`)} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
                spaceBetween={10}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs, Navigation]}
                className='mySwiper'
                // breakpoints={{
                //   1024: {
                //     spaceBetween: 0,
                //     slidesPerView: 4
                //   },
                //   0: {
                //     spaceBetween: 10,
                //     slidesPerView: 4
                //   },

                // }}
              >
                {thisRooms.img.map((img, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <img src={require(`../assets/img/rooms/${img}`)} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>

            <div className='single-product__content content-info'>
              <div className='content-info-heading'>
                <h3 className='content-info-heading__title'>
                  {thisRooms.title}
                </h3>
                <p className='content-info-heading__desc'>
                  <span>${thisRooms.price}</span>day
                </p>
              </div>
              <div className='content-info-location'>
                <span>
                  <i>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/location-black.svg`)
                          .default
                      }
                      alt='location'
                    />
                  </i>
                  <p>{thisRooms.address}</p>
                </span>
              </div>
              <div className='content-info-attributes'>
                <a href='#' className='content-info-attributes__attribute'>
                  {thisRooms.beds} Bedrooms
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  {thisRooms.ft} ft2
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  {thisRooms.baths} Bathroom
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  Individual Thermostats
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  Air Conditioning
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  Ensuite Laundry
                </a>
                <a href='#' className='content-info-attributes__attribute'>
                  Walk-in Closet
                </a>
              </div>
              <div className='content-info-description'>
                <h4 className='content-info-description__title'>Description</h4>
                <p className='content-info-description__desc'>
                  {thisRooms.description}
                </p>
                <div className='content-info-description__showmore'>
                  <input
                    id='read-more-check-1'
                    type='checkbox'
                    className='read-more-check'
                  />
                  <div className='read-more'>
                    <p className='content-info-description__desc'>
                      {thisRooms.description}
                    </p>
                  </div>
                  <label
                    htmlFor='read-more-check-1'
                    className='read-more-label'
                  ></label>
                </div>
              </div>
              <div className='content-info-facilities'>
                <h4 className='content-info-facilities__title'>Facilities</h4>
                <div className='content-info-facilities__wrapper facility'>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/living.svg`)
                          .default
                      }
                      alt='living'
                    />
                    Shared Living Room
                  </span>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/balcony.svg`)
                          .default
                      }
                      alt='balcony'
                    />
                    Balcony
                  </span>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/wifi.svg`)
                          .default
                      }
                      alt='wifi'
                    />
                    WiFi
                  </span>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/heating.svg`)
                          .default
                      }
                      alt='heating'
                    />
                    Heating
                  </span>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/washing.svg`)
                          .default
                      }
                      alt='washing'
                    />
                    Washing Machine
                  </span>
                  <span className='facility__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/conditioner.svg`)
                          .default
                      }
                      alt='conditioner'
                    />
                    Air Conditioner
                  </span>
                </div>
                <div className='content-info-facilities__showmore'>
                  <input
                    id='read-more-check-2'
                    type='checkbox'
                    className='read-more-check'
                  />
                  <div className='read-more'>
                    <div className='content-info-facilities__wrapper facility'>
                      <span className='facility__item'>
                        <img
                          src={
                            require(`../assets/img/home/welcome/font-icon/conditioner.svg`)
                              .default
                          }
                          alt='conditioner'
                        />
                        Air Conditioner
                      </span>
                    </div>
                  </div>
                  <label
                    htmlFor='read-more-check-2'
                    className='read-more-label'
                  ></label>
                </div>
              </div>
              <div className='content-info-availability'>
                <h4 className='content-info-availability__title'>
                  Availability
                </h4>
                <div className='content-info-availability__wrapper'>
                  <p className='content-info-availability__desc'>
                    Available from: <b>26.February 2023</b>
                  </p>
                  <p className='content-info-availability__desc'>
                    Minimum stay: <b>1 week</b>
                  </p>
                  <p className='content-info-availability__desc'>
                    Maximum stay: <b>Not detriment</b>
                  </p>
                  <p className='content-info-availability__desc'>
                    Calendar Updated: <b>1 week ago</b>
                  </p>
                </div>
              </div>
              <div className='content-info-rules'>
                <h4 className='content-info-rules__title'>Landlord rules</h4>
                <div className='content-info-rules__wrapper rules'>
                  <span className='rules__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/smoke.svg`)
                          .default
                      }
                      alt='living'
                    />
                    No smoking allowed
                  </span>
                  <span className='rules__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/pet.svg`)
                          .default
                      }
                      alt='living'
                    />
                    Pets are not allowed
                  </span>
                  <span className='rules__item'>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/overnight.svg`)
                          .default
                      }
                      alt='living'
                    />
                    Overnight guests are allowed
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className='single-layout__sidebar single-sidebar'>
            <div className='single-sidebar-booking booking'>
              <form action='#'>
                <div className='single-sidebar-booking__header booking-header'>
                  <h4 className='booking-header__title'>Booking Request</h4>
                  <div className='booking-header__action action-btn'>
                    <button type='button' className='action-btn__link'></button>
                    <button
                      type='button'
                      className='action-btn__favourite'
                    ></button>
                  </div>
                </div>
                <div className='single-sidebar-booking__reservation booking-reservation'>
                  <Datapicker
                    placeholder={'Check In'}
                    arrow={false}
                    startDate={checkInDate}
                    setStart={setCheckInDate}
                  />
                  <Datapicker
                    placeholder={'Check Out'}
                    arrow={false}
                    startDate={checkOutDate}
                    setStart={setCheckOutDate}
                  />
                </div>
                <div className='single-sidebar-booking__btn booking-btn'>
                  <button type='submit' className='booking-btn__item'>
                    Check Booking
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
        <div className='single-similar'>
          <h2 className='single-similar__title'>
            Similar Properties in Toronto
          </h2>
          <div className='single-similar-products'>
            {roomsData.slice(10, 13).map((item, idx) => {
              return (
                <RoomsCard
                  key={idx}
                  page={item.id}
                  img={item.img}
                  title={item.title}
                  price={item.price}
                  address={item.address}
                  beds={item.beds}
                  baths={item.baths}
                  ft={item.ft}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export { RoomsDetailPage }
